<template>
  <div class="container">
    <input type="radio" v-bind:id="component_id" v-bind:name="component_meta.variable" value="Y" v-model="component_value"
      @click="input_event" />
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {
    let localValue = this.value;

    let localMeta = this.field;

    return {
      // 元件識別碼
      component_id: 'radio-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: localMeta,

      // 元件值
      component_value: localValue,

      // 元件訊息
      component_message: this.message,
    };
  },
  mounted() {
    let $this = this;
    $('#' + this.component_id).click(function () {
      var radios = $('input[type="radio"][name="' + $this.field.variable + '"]');
      radios.not($(this)).not(radios.last()).trigger('dechecked', $this.component_id);
    });

    $('#' + this.component_id).bind('dechecked', function (event, component_id) {
      if (component_id != $this.component_id) {
        $this.component_value = '';
        $this.input_event({ target: { value: '' } });
      }
    });
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;
    }
  },
  methods: {
    input_event: function ($event) {
      this.component_value = $event.target.value;
      this.$emit('input', $event)
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

div.container {
  width: 100%;
  text-align: right;
  margin-top: 5px;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  overflow: hidden;
}
</style>